import { Footer } from "./views/components/Footer";
import { Nav } from "./views/components/Nav";
import { Home } from "./views/Home/index";

function App() {
  return (<></>
    // <div className="ocean-west">
    //   <Home />
    //   <Nav />
    //   <Footer />
    // </div>
  );
}

export default App;
