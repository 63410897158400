import { hydrate } from "react-dom";
import { createRoot } from "react-dom/client"
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const rootElement = document.getElementById("root")!;
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
